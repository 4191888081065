// @file
// Login form styles
//
@import "base/variables";
@import "radix/mixins";

form {
  label {
    display: none;
  }

  input[type="text"], input[type="password"], input.btn {
    border-radius: 0;
    border-style: none;
    width: 70%;
  }

  #edit-name {
    margin-top: 15px;
    background-color: #ececec;
    border-color: #ececec;
  }

  #edit-pass {
    margin-top: 25px;
    background-color: #ececec;
    border-color: #ececec;
  }

  #edit-actions {
    margin-top: 30px;
    margin-bottom: 25px;
    background-color: transparent;
  }

  .password-reset {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  #edit-submit {
    background-color: #c32745;
    text-transform: none;
    font-weight: bold;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;

    @include breakpoint(sm) {
      width: 70%;
    }
  }
}
